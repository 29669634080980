<template>
    <div v-click-outside="hide"  class="bottom-block">
        <a href="javascript:void(0);" >
            <span @click="handle()" :class="{'shown' : condition.isShow}" style="margin-left: 1px; cursor: pointer; color:#24282C;" class="option">Продовжити</span>
        </a>
        <div  v-if="condition.isShow" style="position: relative">
            <div class="prolongation-options">
                <p class="option">
                    <span v-if="isLoadingNew"
                         class="option-loader spinner-border"
                         style="height: 14px; width: 14px; margin-bottom: 4px;"
                         role="status">
                    </span>

                    <span v-if="!isLoadingNew" class="icon">
                        <svg style="margin-bottom: 6px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1141_431)">
                                <path d="M12 9L14 11L12 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2 11H14" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M4 7L2 5L4 3" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14 5H2" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1141_431"><rect width="16" height="16" fill="white"/></clipPath>
                            </defs>
                        </svg>
                    </span>
                    <a @click="prolongNew()" href="javascript:void(0);">
                        <span class="text">Обрати іншу пропозицію</span>
                    </a>
                </p>
                <p class="option">
                    <span v-if="isLoadingCurrent"
                          class="option-loader spinner-border"
                          style="height: 14px; width: 14px; margin-bottom: 4px;"
                          role="status">
                    </span>

                    <span v-if="!isLoadingCurrent" class="icon">
                        <svg style="margin-bottom: 6px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.10955 5.10919V8.38088C7.10955 8.69205 7.27847 8.98543 7.54518 9.14546L10.319 10.7902C10.6391 10.9769 11.048 10.8702 11.2347 10.559C11.4214 10.239 11.3236 9.83002 11.0036 9.64332L8.45201 8.12306V5.1003C8.44312 4.74468 8.14084 4.44241 7.77633 4.44241C7.41182 4.44241 7.10955 4.74468 7.10955 5.10919ZM16 5.77597V1.07292C16 0.672852 15.5199 0.477262 15.2443 0.761756L13.6618 2.34426C12.0526 0.735085 9.7589 -0.189523 7.25179 0.0327389C3.52669 0.370576 0.459488 3.36666 0.0505274 7.09176C-0.4829 11.9104 3.27776 16 7.99859 16C12.0793 16 15.4488 12.9417 15.9378 8.99432C16 8.46089 15.5821 7.99859 15.0487 7.99859C14.6042 7.99859 14.2308 8.32754 14.1775 8.76317C13.7952 11.8659 11.1191 14.2664 7.90969 14.2219C4.61133 14.1775 1.82862 11.3947 1.77528 8.08749C1.72193 4.62022 4.54021 1.77527 7.99859 1.77527C9.71445 1.77527 11.2703 2.47761 12.3994 3.59781L10.5413 5.45592C10.2568 5.74041 10.4524 6.2205 10.8524 6.2205H15.5555C15.8044 6.2205 16 6.02491 16 5.77597Z" fill="black"/>
                        </svg>
                    </span>


                    <a @click="prolongCurrent()" href="javascript:void(0);">
                        <span class="text">Продовжити поточну пропозицію</span>
                    </a>
                </p>
            </div>
        </div>
<!--        <a  href="javascript:void(0);" class="option image">-->
<!--            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <circle cx="2" cy="2" r="2" fill="#999DA6"/>-->
<!--                <circle cx="9" cy="2" r="2" fill="#999DA6"/>-->
<!--                <circle cx="16" cy="2" r="2" fill="#999DA6"/>-->
<!--            </svg>-->
<!--        </a>-->
    </div>
</template>


<script>
import ClickOutside from 'vue-click-outside'
import { orderMixin } from '@/mixin'

export default {
    name: 'ProlongationChoice',
    props: ['statusOrder', 'orderType', 'isLoadingNew', 'isLoadingCurrent'],
    mixins: [orderMixin],
    directives: {ClickOutside},
    data: function () {
        return {
            condition: {
                isShow: false,
                isProcessingNew: false,
                isProcessingCur: false,
            },
        }
    },
    created() {
    },
    computed: {

    },
    events: {},
    methods: {
        prolongNew: function () {
            return this.$emit('prolong-new');
        },
        prolongCurrent: function () {
            return this.$emit('prolong-current');
        },
        hide: function () {
            return this.condition.isShow = false;
        },
        handle: function () {
            return this.condition.isShow = ! this.condition.isShow
        }
    }
}
</script>

<style lang="scss" scoped>
.shown {
    color: #FA9B13!important;
}
.prolongation-options {
    margin-left: -90px;
    top: 20px;
    z-index: 2;
    width: 265px;
    border-radius: 5px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

    .option {
        margin-top: 20px;
        margin-left: 21px;

        a {
            color: #24282C;
            font-size: 14px;
            line-height: 21px;
            font-family: Circe;
            font-style: normal;
            font-weight: normal;

            .icon {

            }

            .text {
                height: 21px;
                margin-left: 5px;
            }
        }
    }
}
</style>
