<template>
    <div class="p-0">
        <div class="list-orders container p-0">
            <div v-if="! isMobile" class="tabs-block"></div>
            <div v-else></div>

            <div class="container empty-notification" v-if="! isLoadData && (orders && orders.length < 1)">
                <img src="~@/assets/images/empty_img.svg" alt="">
                <div class="empty-notification__text">
                    <p v-if="isProlongation()">Тут будуть з`являтися страховi полiси, дата закiнчення яких через 30 днiв, або ранiше.
                        Оформіть страховку або змініть параметри  <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a>  замовлень.</p>
                    <p v-else-if="isDraftTab()">Тут будуть з'являтися страхові поліси зі статусом чернетка. Створіть чернетку або змініть параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> замовлень.</p>
                    <p v-else-if="isDeclaredTab()">Тут будуть з'являтися страхові поліси зі статусом заявлений. Заявіть чернетку або змініть параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> замовлень.</p>
                    <p v-else-if="isCanceledTab()">Тут будуть з'являтися страхові поліси зі статусом припинений. Змінити параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> замовлень.</p>
                    <p v-else>Ваша історія замовлень поки порожня. Оформіть страховку або змініть параметри
                        <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> замовлень.</p>
                </div>
                <a data-toggle="modal" data-target="#modalSearch" class="header__create">Cтворити
                    страховку</a>
            </div>
            <div class="container-wrapp" v-else-if="! isMobile">
                <div class="header-block">
                    <ul class="columns-block">
                        <li class="column">Тип статусу</li>
                        <li class="column">Номер поліса</li>
                        <li class="column">Дата</li>
                        <li class="column">Страхувальник</li>
                        <li class="column">Вартість, грн</li>
                        <li class="column">Заробіток, грн</li>
                    </ul>
                </div>
                <div v-if="isLoadData">
                    <row-block-skeleton v-for="index in 4" :key="index"></row-block-skeleton>
                </div>
                <div v-else v-for="order in orders" class="row-block">
                    <ul class="columns-block">
                        <li class="column">
                            <div class="column-block">
                                <span class="order-type">{{ showTypeInsurance(order) }}</span>
                                <span v-if="isProlongationOrder(order)" style="margin-top: 10px">
                                  <svg style="margin-top: -7px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="10" y1="4" x2="10" y2="12" stroke="#EF4E4E" stroke-width="2"/>
                                    <line x1="10" y1="13.5" x2="10" y2="16" stroke="#EF4E4E" stroke-width="2"/>
                                    <circle cx="10" cy="10" r="9" stroke="#EF4E4E" stroke-width="2"/>
                                  </svg>
                                    <span>
                                        Cкоро закінчується
                                    </span>
                                </span>
                                <div class="bottom-part order-status">
                                    <div class="bottom-block right-border">
                                        <span>Статус:</span>
                                        <div :class="['status', getStatusClass(order)]"><span>{{
                                                showStatusName(order)
                                            }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <a  class="order-number" target="_blank"  :href="showMtsbuLink(order)" v-if="showMtsbuLink(order)">
                                    {{ showMtsbuCode(order) }}<br />{{ showMtsbuCodeAdditionalInfo(order) }}
                                </a>
                                <span v-else-if="showMtsbuCode(order)" class="order-number">
                                    {{ showMtsbuCode(order) }}
                                </span>
                                <span class="order-number" v-else>&mdash;</span>
<!--                                <span class="order-number" v-if="showMtsbuCode(order)">{{ showMtsbuCode(order) }}</span>-->
<!--                                <span class="order-number" v-else>&mdash;</span>-->
                                <div class="bottom-part order-user">
                                    <div class="bottom-block right-border">
                                        <span>Точка продажу:</span>
                                        <div class="user-name"
                                             data-toggle="tooltip" data-placement="bottom"
                                             :title="showNameInsured(order)">
                                            {{ showNameInsured(order) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div>
                                    <div class="date-block">
                                        <div class="text">Створений:</div>
                                        <span v-if="order.createdAt" class="date">{{ order.createdAt }}</span>
                                        <span v-else class="date">&mdash;</span>

                                    </div>
                                    <div class="date-block">
                                        <div class="text">Початок:</div>
                                        <span v-if="showStartedAtContract(order)"
                                              class="date">{{ showStartedAtContract(order) }}</span>
                                        <span v-else class="date">&mdash;</span>
                                    </div>
                                    <div class="date-block">
                                        <div class="text">Закінчення:</div>
                                        <span v-if="showFinishedAtContract(order)"
                                              class="date">{{ showFinishedAtContract(order) }}</span>
                                        <span v-else class="date">&mdash;</span>

                                    </div>
                                </div>
                                <div class="bottom-part order-company">
                                    <div class="bottom-block right-border">
                                        <span class="option">СК:</span>
                                        <div class="result"
                                             data-toggle="tooltip" data-placement="bottom"
                                             :title="order.companyInfo.name">
                                            <ellipsis
                                                :data="order.companyInfo.name">
                                            </ellipsis>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div class="order-insured">
                                    <div v-if="showNameInsurantSurname(order)">
                                        <span>{{ showNameInsurantSurname(order) }}</span>
                                    </div>
                                    <div style="">
                                        <span>{{ showNameInsurantName(order) }}</span>
                                    </div>
                                    <div style="">
                                        <span>{{ showNameInsurantPatronymic(order) }}</span>
                                    </div>
                                </div>

                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div>
                                    <div class="price-block">
                                        <div class="text">Первісна:</div>
                                        <span class="date">{{ showPrice(order) }}</span>
                                    </div>
                                    <div v-if="showDiscount(order) || showMonths(order)" class="price-block">
                                        <div class="text">Зі знижкою:</div>
                                        <span class="date">{{ showAllPrice(order) }} <i class="icon question" data-html="true" data-placement="bottom" data-select="true" data-toggle="tooltip" title="Первісна вартість може бути знижена за рахунок знижки посередника та за рахунок виключення місяців, з періоду дії полісу."></i></span>
                                    </div>
<!--
                                    <div v-if="showDgoPrice(order)" class="price-block">
                                        <div class="text">ДЦВ:</div>
                                        <span class="date">{{ showDgoPrice(order) }}</span>
                                    </div>
                                    <div v-if="showDgoPrice(order)" class="price-block">
                                        <div class="text">Заголом:</div>
                                        <span class="date">{{ showAllPrice(order) }}</span>
                                    </div>
-->
                                </div>
                                <div class="bottom-part">
                                    <div class="bottom-part">
                                        <div>
                                            <svg style="margin-bottom: 4px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.6 3.2002H4.4C3.07452 3.2002 2 4.27471 2 5.6002V11.6002C2 12.9257 3.07452 14.0002 4.4 14.0002H11.6C12.9255 14.0002 14 12.9257 14 11.6002V5.6002C14 4.27471 12.9255 3.2002 11.6 3.2002Z" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.59961 2V4.4" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M10.4004 2V4.4" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 6.80029H14" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <div class="d-inline-flex">
                                                <prolongation-choice
                                                    @prolong-new="prolongNew(order)"
                                                    @prolong-current="prolong(order)"
                                                    :isLoadingNew="loadProcessNew"
                                                    :isLoadingCurrent="loadProcessCurrent"
                                                >
                                                </prolongation-choice>
                                            </div>

<!--                                            <a @click="showProlongationModal" href="javascript:void(0);">-->
<!--                                                <span  style="margin-left: 1px;" class="option">Продовжити</span>-->
<!--                                            </a>-->

                                            <div class="d-inline-flex">
                                                <client-info :client="order.insurant"  ref="clientInfo"></client-info>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
 <!--                           <div>
                                    <div class="price-block" v-if="order.allEarnings">
                                        <div class="text">Загальний:</div>
                                        <span class="price">{{ order.allEarnings + (order.allDgoEarning ? ` (${order.allDgoEarning})` : '') }}</span>
                                    </div>
                                    <div class="price-block">
                                        <div class="text">Ваш:</div>
                                        <span v-if="order.myEarnings" class="price">{{ order.myEarnings + (order.myDgoEarning ? ` (${order.myDgoEarning})` : '')}}</span>
                                        <span v-else class="price">&mdash;</span>
                                    </div>
                                    <div class="price-block" v-if="order.isPartnerOrder">
                                        <div class="text">Агента:</div>
                                        <span v-if="order.partnerEarnings" class="price">{{
                                                order.partnerEarnings  + (order.partnerDgoEarning ? ` (${order.partnerDgoEarning})` : '')
                                            }}</span>
                                        <span v-else class="date">&mdash;</span>
                                    </div>
                                </div>
-->
                                <div>
                                    <div class="price-block" v-if="order.allEarnings">
                                        <div class="text">Загальний:</div>
                                        <span class="price">{{ order.allEarnings }}</span>
                                    </div>
                                    <div class="price-block">
                                        <div class="text">Ваш:</div>
                                        <span v-if="order.myEarnings" class="price">{{ order.myEarnings }}</span>
                                        <span v-else class="price">&mdash;</span>
                                    </div>
                                    <div class="price-block" v-if="order.isPartnerOrder">
                                        <div class="text">Агента:</div>
                                        <span v-if="order.partnerEarnings" class="price">{{
                                                order.partnerEarnings
                                            }}</span>
                                        <span v-else class="date">&mdash;</span>
                                    </div>
                                </div>
                                <div class="bottom-part">
                                    <div v-if="loadMoreOptions[order.id + order.typeId] === true"
                                         class="option-loader spinner-border"
                                         style="height: 14px; width: 14px; margin-bottom: 4px;"
                                         role="status">
                                    </div>
                                    <more-options v-else :status-order="showStatusId(order)"
                                                  :order-type="order.typeId"
                                                  @download-draft="showDraft(order)"
                                                  @download-contract="showContract(order)"
                                                  @show-order="goToOrderInfo(order)"
                                                  @proceed-order="declare(order)"
                                                  @confirm-order="declare(order,false,false,2)"
                                                  @open-mtsbu-link="openMtsbuLink(order)"
                                                  @get-payment-invoice="getPaymentInvoice(order)"
                                                  :is-exist-mtsbu-link="showMtsbuLink(order)"
                                                  @copy-order="copy(order)">
                                    </more-options>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>






            <div v-else>
                <div class="mobile-columns-block">
                    <div v-if="isLoadData">
                        <row-mobile-block-skeleton v-for="index in 4" :key="index"></row-mobile-block-skeleton>
                    </div>
                    <div v-else>
                        <div v-for="order in orders" class="row order-data">
                            <div v-if="isProlongationOrder(order)" class="col-12 mb-3">
                                <div style="margin-top: 10px">
                                        <span>
                                          <svg style="margin-top: -7px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="10" y1="4" x2="10" y2="12" stroke="#EF4E4E" stroke-width="2"/>
                                            <line x1="10" y1="13.5" x2="10" y2="16" stroke="#EF4E4E" stroke-width="2"/>
                                            <circle cx="10" cy="10" r="9" stroke="#EF4E4E" stroke-width="2"/>
                                          </svg>
                                        </span>
                                    <span>
                                       Скоро закінчується
                                    </span>
                                </div>
                            </div>
                            <div class="col-7 half-block">
                                <div class="info-block">
                                    <div class="option">Тип поліса</div>
                                    <div class="value">{{ showTypeInsurance(order) }}</div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Дата</div>
                                    <div class="sub-options">
                                        <div>
                                            <span class="sub-option">Створений:</span>
                                            <span v-if="order.createdAt" class="value">{{ order.createdAt }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                        <div>
                                            <span class="sub-option">Початок:</span>
                                            <span v-if="showStartedAtContract(order)"
                                                  class="value">{{ showStartedAtContract(order) }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                        <div>
                                            <span class="sub-option">Закінчення:</span>
                                            <span v-if="showFinishedAtContract(order)"
                                                  class="value">{{ showFinishedAtContract(order) }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Вартість, грн</div>
                                    <div class="sub-options">
                                        <div>
                                            <span class="sub-option">Первісна:</span>
                                            <span class="value">{{ showPrice(order) }}</span>
                                        </div>
                                        <div v-if="showDiscount(order)">
                                            <span class="sub-option">Зі знижкою:</span>
                                            <span class="value">{{ showDiscount(order) }}</span>
                                        </div>
                                        <div v-if="showDgoPrice(order)">
                                            <span class="sub-option">ДЦВ:</span>
                                            <span class="value">{{ showDgoPrice(order) }}</span>
                                        </div>

                                        <div class="mt-4 mb-1">
                                            <span class="sub-option">Статус:</span>
                                            <div :class="['value status', getStatusClass(order)]">
                                                <span>{{ showStatusName(order) }}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <span class="sub-option">Точка продажу:</span>
                                            <span data-toggle="tooltip" data-placement="bottom"
                                                  :title="showNameInsured(order)"
                                                  class="value">
                                            {{ showNameInsured(order) }}
                                        </span>
                                        </div>
                                        <div class="d-flex">
                                            <span class="sub-option">СК:</span>
                                            <span class="value">
                                            <ellipsis :data="order.companyInfo.name"></ellipsis>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 half-block">
                                <div class="info-block">
                                    <div class="option">Номер поліса</div>
                                    <div class="value">
                                        <a :href="showMtsbuLink(order)" v-if="showMtsbuLink(order)">
                                            {{ showMtsbuCode(order) }}
                                        </a>
                                        <span v-else-if="showMtsbuCode(order)" class="order-number">
                                            {{ showMtsbuLink(order) }}
                                        </span>
                                        <span class="order-number" v-else>&mdash;</span>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Страхувальник</div>
                                    <div class="value">
                                        {{ showNameInsurantSurname(order) }}
                                    </div>
                                    <div class="value">
                                        {{ showNameInsurantName(order) }}
                                    </div>
                                    <div class="value">
                                        {{ showNameInsurantPatronymic(order) }}
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Заробіток, грн</div>
                                    <div class="sub-options">
                                        <div v-if="order.isPartnerOrder">
                                            <span class="sub-option">Загальний:</span>
                                            <span v-if="order.allEarnings" class="value">{{ order.allEarnings }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                        <div>
                                            <span class="sub-option">Ваш:</span>
                                            <span v-if="order.myEarnings" class="value">{{ order.myEarnings }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                        <div v-if="order.isPartnerOrder">
                                            <span class="sub-option">Агента:</span>
                                            <span v-if="order.partnerEarnings" class="value">{{
                                                    order.partnerEarnings
                                                }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-top: auto;color: #999DA6;" class="info-block">
                                    <!-- v-if="isProlongation(order)" -->
                                    <div class="info-link">
                                        <svg style="margin-bottom: 4px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.6 3.2002H4.4C3.07452 3.2002 2 4.27471 2 5.6002V11.6002C2 12.9257 3.07452 14.0002 4.4 14.0002H11.6C12.9255 14.0002 14 12.9257 14 11.6002V5.6002C14 4.27471 12.9255 3.2002 11.6 3.2002Z" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M5.59961 2V4.4" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.4004 2V4.4" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M2 6.80029H14" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

<!--                                        <span @click="showProlongationModal">Продовжити</span>-->
                                        <div class="d-inline-flex" style="margin-bottom: 15px">
                                            <prolongation-choice
                                                @prolong-new="prolongNew(order)"
                                                @prolong-current="prolong(order)"
                                                :isLoadingNew="loadProcessNew"
                                                :isLoadingCurrent="loadProcessCurrent"
                                            >
                                            </prolongation-choice>
                                        </div>

<!--                                        <div class="d-inline-flex" style="margin-left: -10px;">-->
<!--                                            <client-info :client="order.insurant"  ref="clientInfo"></client-info>-->
<!--                                        </div>-->


                                    </div>
                                    <div class="info-link" v-if="isConcluded(order)">
                                      <svg width="16" height="16"
                                           viewBox="0 0 16 16" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                        <path
                                            d="M0.666504 8C0.666504 8 3.33317 2.66667 7.99984 2.66667C12.6665 2.66667 15.3332 8 15.3332 8C15.3332 8 12.6665 13.3333 7.99984 13.3333C3.33317 13.3333 0.666504 8 0.666504 8Z"
                                            stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path
                                            d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                            stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0">
                                            <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                        <span @click="goToOrderInfo(order)">Переглянути</span>
                                    </div>
                                    <div class="info-link" v-else-if="isOverdue(order)">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                                            stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path
                                            d="M3.3335 10H2.66683C2.31321 10 1.97407 9.85952 1.72402 9.60947C1.47397 9.35942 1.3335 9.02028 1.3335 8.66666V2.66666C1.3335 2.31304 1.47397 1.9739 1.72402 1.72385C1.97407 1.4738 2.31321 1.33333 2.66683 1.33333H8.66683C9.02045 1.33333 9.35959 1.4738 9.60964 1.72385C9.85969 1.9739 10.0002 2.31304 10.0002 2.66666V3.33333"
                                            stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        </svg>
                                        <span @click="copy(order)">Копіювати</span>
                                    </div>
                                    <div class="info-link" v-else-if="isDraft(order)">
                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.0415 7.74999C12.0398 8.88764 11.5871 9.9782 10.7827 10.7826C9.97822 11.5871 8.88766 12.0398 7.75001 12.0415C6.61223 12.0399 5.52149 11.5873 4.71686 10.7828C3.91223 9.97841 3.45936 8.88777 3.45751 7.74999C3.45896 6.61199 3.91167 5.52103 4.71636 4.71634C5.52104 3.91166 6.61201 3.45894 7.75001 3.45749C8.70651 3.45749 9.58251 3.77249 10.295 4.30049L9.40401 5.19249L13.619 6.32049L12.489 2.10699L11.544 3.05199C10.4712 2.18163 9.13151 1.70689 7.75001 1.70749C4.41301 1.70899 1.70901 4.41249 1.70801 7.74999C1.70901 11.0875 4.41301 13.7915 7.75001 13.7915C11.087 13.7915 13.7915 11.0875 13.7915 7.74999H12.0415Z"
                                                fill="#999DA6"/>
                                        </svg>
                                        <span @click="declare(order)">Продовжити</span>
                                    </div>
                                    <div class="info-link" v-else-if="isDeclared(order)">
                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.0415 7.74999C12.0398 8.88764 11.5871 9.9782 10.7827 10.7826C9.97822 11.5871 8.88766 12.0398 7.75001 12.0415C6.61223 12.0399 5.52149 11.5873 4.71686 10.7828C3.91223 9.97841 3.45936 8.88777 3.45751 7.74999C3.45896 6.61199 3.91167 5.52103 4.71636 4.71634C5.52104 3.91166 6.61201 3.45894 7.75001 3.45749C8.70651 3.45749 9.58251 3.77249 10.295 4.30049L9.40401 5.19249L13.619 6.32049L12.489 2.10699L11.544 3.05199C10.4712 2.18163 9.13151 1.70689 7.75001 1.70749C4.41301 1.70899 1.70901 4.41249 1.70801 7.74999C1.70901 11.0875 4.41301 13.7915 7.75001 13.7915C11.087 13.7915 13.7915 11.0875 13.7915 7.74999H12.0415Z"
                                                fill="#999DA6"/>
                                        </svg>
                                        <span @click="declare(order,false,false,2)">Продовжити</span>
                                    </div>
                                    <div>
                                        <div v-if="loadMoreOptions[order.id + order.typeId] === true"
                                             class="option-loader spinner-border"
                                             style="height: 20px; width: 20px; margin-top: 11px;"
                                             role="status">
                                        </div>
                                        <more-options style="padding-top: 5px"
                                                      v-else :status-order="showStatusId(order)"
                                                      @download-draft="downloadDraft(order)"
                                                      @download-contract="downloadContract(order)"
                                                      @show-order="goToOrderInfo(order)"
                                                      @proceed-order="declare(order)"
                                                      @confirm-order="declare(order,false,false,2)"
                                                      @open-mtsbu-link="openMtsbuLink(order)"
                                                      @get-payment-invoice="getPaymentInvoice(order)"
                                                      :is-exist-mtsbu-link="showMtsbuLink(order)"
                                                      @copy-order="copy(order)"
                                        >
                                        </more-options>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-get-invoice-order ref="modalGetInvoiceOrder"></modal-get-invoice-order>
        <prolongation-modal ref="prolongationModal"
            @prolong-new="prolongNew"
            @hide-prolongation-modal="hideProlongationModal"
        ></prolongation-modal>
    </div>
</template>


<script>
import Vue from "vue";
import {orderMixin, customerMixin, HistoryMixin} from '@/mixin'
import {calculate} from "@/api/offer";
import {list as calculateGreenCard} from "@/api/greenCardInsuranceCompany";
import {concludeOrder, downloadContract, downloadOrder, downloadPdf, getPaymentInvoice} from "@/api/order";
import {downloadBusContract} from "@/api/downloadBusContract"
import {downloadGreenCardContract} from "@/api/downloadGreenCardContract"
import {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientInfo, ProlongationChoice, ProlongationModal} from './components'
import { GetInvoiceOrder as ModalGetInvoiceOrder } from "@/components/app/modals/order";
import {contractGreenCard} from "@/api/contractGreenCard";
import {list as calculateTourism} from "@/api/tourismInsuranceCompany";

const FileDownload = require('js-file-download');

export default {
    mixins: [orderMixin, customerMixin, HistoryMixin],
    components: {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientInfo, ModalGetInvoiceOrder, ProlongationChoice, ProlongationModal},
    props: {
        countProlongation: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        isLoadData: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        isMobile: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        prolongation: {
            type: Array,
            default: () => {
                return [{
                    dgo: {},
                    statusId: null,
                    fullName: null,
                    earning: null,
                    contract: {},
                    companyInfo: {},
                    insurant: {},
                    insured: {},
                    price: null,
                    dgoPrice: null,
                    autolawyerPrice: null,
                    discountPrice: null,
                }];
            },
        },
        orders: {
            type: Array,
            default: () => {
                return [{
                    dgo: {},
                    statusId: null,
                    fullName: null,
                    earning: null,
                    contract: {},
                    companyInfo: {},
                    insurant: {},
                    insured: {},
                    price: null,
                    dgoPrice: null,
                    autolawyerPrice: null,
                    discountPrice: null,
                }];
            },
        },
    },

    data: function () {
        return {
            loadContract: {},
            loadConclude: {},
            loadMoreOptions: {},
            loadProlongationModal: false,
            loadProcessNew: false,
            loadProcessCurrent: false,
            isCopyProcess: false,
            listQuery: {
                statusId: 0,
            },
            searchData: {
                carType: {"name":"Легкові автомобілі","group":"CAR", "external_id":"A",
                    "engines":[{"name":"до 1600 см3","value":"A"},
                        {"name":"1601-2000 см3","value":"A21"},
                        {"name":"2001-3000 см3","value":"A3"},
                        {"name":"более 3001 см3","value":"A4"}], "types":[]},
            },
            experience: {"name":"Менше 3 років","value":false},
            periodOptions: [
                {"id": 15, "count":"15", "key":"d", "name" : "15 д" },
                {"id": 1, "count":"1", "key":"m", "name" : "1 міс" },
                {"id": 2, "count":"2", "key":"m", "name" : "2 міс" },
                {"id": 3, "count":"3", "key":"m", "name" : "3 міс" },
                {"id": 4, "count":"4", "key":"m", "name" : "4 міс" },
                {"id": 5, "count":"5", "key":"m", "name" : "5 міс" },
                {"id": 6, "count":"6", "key":"m", "name" : "6 міс" },
                {"id": 7, "count":"7", "key":"m", "name" : "7 міс" },
                {"id": 8, "count":"8", "key":"m", "name" : "8 міс" },
                {"id": 9, "count":"9", "key":"m", "name" : "9 міс" },
                {"id": 10, "count":"10", "key":"m", "name" : "10 міс" },
                {"id": 11, "count":"11", "key":"m", "name" : "11 міс" },
                {"id": 12, "count":"12", "key":"m", "name" : "12 міс" },
            ],
            carTypes: [
                {"name":"Легкові автомобілі","group":"CAR","external_id":"B1",
                "types":[{"name":"до 1600 см3","value":"B1"},{"name":"1601-2000 см3","value":"B2"},{"name":"2001-3000 см3","value":"B3"},
                    {"name":"более 3001 см3","value":"B4"}],"engines":[]},
                {"name":"Автобус","group":"BUS","engines":[],"types":[{"name":"менше 20 осіб","value":"D1"},
                        {"name":"більше 20 осіб","value":"D2"}]},{"name":"Вантажні автомобілі","group":"CARGO","engines":[],
                    "types":[{"name":"менше 2-х тонн","value":"C1"},{"name":"більше 2-х тонн","value":"C2"}]},
                {"name":"Причіп, Трейлер","group":"TRAILER","engine":null,"engines":[],"types":[{"name":"До вантажного авто","value":"E"},{"name":"До легкового авто","value":"F"}]},{"name":"Мотоцикли та моторолери"
                    ,"group":"BIKE","external_id":"A1","types":[{"name":"300 см3 або менше","value":"A1"},{"name":"більше 300 см3","value":"A2"}],"engines":[]},
                {"name":"Легковий електромобіль","external_id":"B5","engine":null,"engines":[],"types":[]}
            ],
            carTypesGreenCard: [
                {"name":"Легкові автомобілі","group":"CAR", "external_id":"A",
                "engines":[
                    {"name":"до 1600 см3","value":"A1"},
                    {"name":"1601-2000 см3","value":"A2"},
                    {"name":"2001-3000 см3","value":"A3"},
                    {"name":"более 3001 см3","value":"A4"}],
                "types":[]},
                {"name":"Автобус","group":"BUS", "external_id":"E", "engines":[],
                    "types":[
                        {"name":"менше 20 осіб","value":"E1"},
                        {"name":"більше 20 осіб","value":"E2"}]},
                {"name":"Вантажні автомобілі","group":"CARGO", "external_id":"C", "engines":[],
                    "types":[
                        {"name":"менше 2-х тонн","value":"C1"},
                        {"name":"більше 2-х тонн","value":"C2"}]},
                {"name":"Причіп, Трейлер","group":"TRAILER","engine":null, "external_id":"F", "engines":[],
                    "types":[{"name":"До вантажного авто","value":"F"},
                        {"name":"До легкового авто","value":"F"}]},
                {"name":"Мотоцикли та моторолери","group":"BIKE", "external_id":"B",
                    "engines":[{"name":"300 см3 або менше","value":"B1"},
                        {"name":"більше 300 см3","value":"B2"}], "types":[]}
            ],
            countriesOptions: [
                {"id": 60,"name": "Європа","name_ru": "Европа"},
                {"id": 117,"name": "Молдова","name_ru": "Молдова"}
            ],
        }
    },
    created() {

    },
    computed: {
        orderTypes: function () {
            return this.$store.getters.ORDER_TYPES
        },
        allOrderTypes: function () {
            return this.$store.getters.ALL_ORDER_TYPES
        }
    },
    methods: {
        showClientInfo() {
            this.$refs.clientInfo.handle(true);
        },
        isProlongationOrder: function (order) {
            return order.isProlongation;
        },
        openMtsbuLink: function (order) {
            window.open(this.showMtsbuLink(order), '_blank');
        },
        showFinishedAtContract: function(order) {
            return order.contract.finishedAt;
        },
        showStartedAtContract: function(order) {
            return order.contract.startedAt;
        },
        filterStatusWrap: function() {
            return this.$emit('filter-status-wrap');
        },
        showDraft(order) {
            downloadOrder(this.$store.getters.USER.id, order.id);
        },
        getPaymentInvoice: async function(order) {
            this.$refs.modalGetInvoiceOrder.open(order.id);
        },
      /* downloadDraft: async function(order) {
          Vue.set(this.loadMoreOptions, order.id + order.typeId, true);

          let pdf = await downloadPdf(`/api/orders/draft/${this.$store.getters.USER.id}/${order.id}`);

          if (pdf?.data) {
              FileDownload(pdf.data, 'draft.pdf');
          }

          Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
        },
        conclude: async function(order) {
          await this.$swal({
              title: 'Ви дійсно бажаєте оформити цей договір?',
              type: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Ні, не варто!',
              confirmButtonText: 'Так, звісно!',
              showCloseButton: true,
              showLoaderOnConfirm: true
          }).then((result) => {
              if (result.value) {
                  Vue.set(this.loadMoreOptions, order.id + order.typeId, true);
                  if(order.typeId == 1) {
                      concludeOrder(order.id)
                          .then((response) => {
                              Vue.set(this.loadMoreOptions, order.id + order.typeId, false);

                              if (response?.data?.status === 'success') {
                                  this.$alert("ОСЦПВ вдало оформлено!", '',);
                                  this.$router.push({name: "history"});
                                  this.$emit('init-orders-list');

                                  return true;
                              }

                              this.$alert("ОСЦПВ не оформлено!", '', 'error');
                          }).catch((error) => {
                          Vue.set(this.loadMoreOptions, order.id + order.typeId, false);

                          this.$alert("ОСЦПВ не оформлено!", '', 'error');
                      });
                  }else if(order.typeId == 3){
                      let data = {
                          orderId: order.id,
                      };
                      contractGreenCard(data).then((response) => {
                          Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
                          if (response?.data?.status === 'success' && !!response.data.data[0].contractId) {
                              this.$alert("Зелена картка вдало оформлена!", '',);
                              this.$router.push({name: "history"});
                              this.$emit('init-orders-list');

                              return true;
                          }

                          this.$alert("Зелена Картка не оформлена", '', 'error');
                      }).catch((error) => {
                          Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
                          if (error?.response?.data?.status == 'error') {
                              this.$alert("Зелена Картка не оформлена", '', 'error');
                          }
                          else if (error?.response?.data?.data?.orderId[0]?.includes('недостатньо коштів')) {
                              this.$alert(error?.response?.data?.data?.orderId[0], '', 'error');
                          } else {
                              this.$alert("Зелена Картка не оформлена", '', 'error');
                          }
                      });
                  }
              }
          })
      },
      getCarType: function(order) {
          let carType = {};

          if (order.typeId === 1) {
              if(order.insuranceObject.autoCategory == 'B1' || order.insuranceObject.autoCategory == 'B2' || order.insuranceObject.autoCategory == 'B3' || order.insuranceObject.autoCategory == 'B4'){
                  carType = this.carTypes[0];
              }else if(order.insuranceObject.autoCategory == 'D1' || order.insuranceObject.autoCategory == 'D2'){
                  carType = this.carTypes[1];
              }else if(order.insuranceObject.autoCategory == 'C1' || order.insuranceObject.autoCategory == 'C2'){
                  carType = this.carTypes[2];
              }else if(order.insuranceObject.autoCategory == 'E' || order.insuranceObject.autoCategory == 'F'){
                  carType = this.carTypes[3];
              }else if(order.insuranceObject.autoCategory == 'A1' || order.insuranceObject.autoCategory == 'A2'){
                  carType = this.carTypes[4];
              }else if(order.insuranceObject.autoCategory == 'B5'){
                  carType = this.carTypes[5];
              }
          }

          if (order.typeId === 3) {
              if(order.insuranceObject.autoCategory == 'A' || order.insuranceObject.autoCategory == 'A1' || order.insuranceObject.autoCategory == 'A2' || order.insuranceObject.autoCategory == 'A3' || order.insuranceObject.autoCategory == 'A4'){
                  carType = this.carTypesGreenCard[0];
              }else if(order.insuranceObject.autoCategory == 'E' || order.insuranceObject.autoCategory == 'E1' || order.insuranceObject.autoCategory == 'E2'){
                  carType = this.carTypesGreenCard[1];
              }else if(order.insuranceObject.autoCategory == 'C' || order.insuranceObject.autoCategory == 'C1' || order.insuranceObject.autoCategory == 'C2'){
                  carType = this.carTypesGreenCard[2];
              }else if(order.insuranceObject.autoCategory == 'E' || order.insuranceObject.autoCategory == 'F'){
                  carType = this.carTypesGreenCard[3];
              }else if(order.insuranceObject.autoCategory == 'B' || order.insuranceObject.autoCategory == 'B1' || order.insuranceObject.autoCategory == 'B2'){
                  carType = this.carTypesGreenCard[4];
              }
          }

          return carType;
      },
    getStartDate: function (order) {

          let startDT = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1));//.format('DD.MM.YYYY');
          let startDateUnix = startDT.unix();
          let startDate = startDT.format('DD.MM.YYYY');
          const orderContractFinished = moment(order.contract?.finishedAt, "DD.MM.YYYY").add(1, 'days').unix();

          if (orderContractFinished && (orderContractFinished > startDateUnix)) {
              startDate = moment(order.contract?.finishedAt, "DD.MM.YYYY").add(1, 'days').format('DD.MM.YYYY');
          }

          return startDate;
      },
      getOrderContractPeriod(order){
          let currentYear = parseInt(moment(order.contract.finishedAt,'DD/MM/YYYY').format('YYYY'));
          let currentMonth = parseInt(moment(order.contract.finishedAt,'DD/MM/YYYY').format('MM'));
          let currentDay = parseInt(moment(order.contract.finishedAt,'DD/MM/YYYY').format('DD'));
          let startYear = parseInt(moment(order.contract.startedAt,'DD/MM/YYYY').format('YYYY'));
          let startMonth = parseInt(moment(order.contract.startedAt,'DD/MM/YYYY').format('MM'));
          let startDay = parseInt(moment(order.contract.startedAt,'DD/MM/YYYY').format('DD'));

          let diffYears = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'years', true);
          let diffMonths = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'months', true);
          let diffDays = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'days', true);

          let orderPeriod = {};
          let period;

          if(diffYears >= 1) {
              let count = Math.round(diffYears);
              let months = Math.ceil(diffMonths);

              if(count >= 2) {
                  orderPeriod.key = 'y'
                  orderPeriod.count = count
                  orderPeriod.name = count +' роки'
                  orderPeriod.countMonths = months;
              } else if(count >= 5) {
                  orderPeriod.key = 'y'
                  orderPeriod.count = count
                  orderPeriod.name = count +' років'
                  orderPeriod.countMonths = months;
              } else {
                  orderPeriod.key = 'y'
                  orderPeriod.count = count
                  orderPeriod.name = count +' рік'
                  orderPeriod.countMonths = months;
              }
              period = orderPeriod;
          } else if(diffDays > 15) {
              let count = Math.ceil(diffMonths);

              if(count < 5 && count > 1){
                  orderPeriod = count //+' місяці'
              }else if(count >= 5){
                  orderPeriod = count //+' місяців'
              }else{
                  orderPeriod = count //+' місяць'
              }
              period = this.periodOptions.find(item => item.id === orderPeriod)

          } else{
              let count = diffDays;
              if(count <= 15){
                  orderPeriod = 15;//= '15 днів'
              }else{
                  orderPeriod = count //+' днів'
              }
              period = this.periodOptions.find(item => item.id === orderPeriod)
          }

          return period;
      },
      getOrderEndDate(order, startOfferDate) {

          let newEndDate;
          const periodForEnd = this.getOrderContractPeriod(order);

          if (periodForEnd.key && periodForEnd.key === "y") {
              newEndDate = moment(startOfferDate, "DD.MM.YYYY").add(periodForEnd.countMonths, 'months');
          } else if (periodForEnd.key && periodForEnd.key === "m") {
              newEndDate = moment(startOfferDate, "DD.MM.YYYY").add(periodForEnd.count, 'months');
          } else if (periodForEnd.key && periodForEnd.key === "d") {
              newEndDate = moment(startOfferDate, "DD.MM.YYYY").add(periodForEnd.count, 'days');
          }

          return newEndDate.utc(true).unix();
      },*/
        hideProlongationModal() {
            this.loadProlongationModal = !this.loadProlongationModal;
        },
        prolong: async function(order) {
            this.loadProcessCurrent = true;
            await this.copy(order, true);
        },
        prolongNew: async function(order) {
            this.loadProcessNew = true;
            await this.copy(order, true, true);
        },
       /* copy: async function(copiedOrder, isProlong = false, isNewProlong = false) {
            //console.log('++++ copiedOrder ++++', copiedOrder);
            let order = Object.assign({}, copiedOrder);
            Vue.set(this.loadMoreOptions, order.id + order.typeId, true);

            this.isCopyProcess = true;

            let offer = {};
            let carType = this.getCarType(order);
            let startDate = this.getStartDate(order);


            if (order.typeId === 1) {

                let dataCalculate = {
                    franchise: ~~order.offer.franchise,
                    autoCategoryType: order.insuranceObject.autoCategory,
                    registrationPlaceId: order.offer.cityId,
                    customerPrivilege: order.insurant.privilegeTypeId,
                    period_id: order.offer.period_id ?? this.getOrderContractPeriod(order).id,
                    otkDate: order.offer.otkDate ? this.getOrderEndDate(order, startDate) : null,//this.getOrderEndDate(order, startDate) : null,   moment(order.offer.otkDate).unix() : null
                    isTaxi: order.insuranceObject.isTaxi,
                    isEuroCar: order.insuranceObject.isEuroCar,
                    customerType: order.insurant.customerTypeId,
                    registrationType: order.registrationType,
                    usageMonths: order.usageMonths,
                    startDate: startDate,
                    zone: order.insuranceObject?.registrationPlaceZone,
                };

                if (!this.isPermanentRegistrationType(dataCalculate.registrationType)) {
                    // moment(order.contract.finishedAt, "DD.MM.YYYY").utc(true).unix();
                    dataCalculate.endDate = this.getOrderEndDate(order, startDate);
                }

                let response = await calculate(dataCalculate);

                offer = this.$_.find(response?.data?.data, (item) => {
                    return item.company.id === order.companyInfo.companyId && item.programmName === order.offer.programmName;
                });
                order.offer.dateFromMax = offer?.dateFromMax;
                //console.log('copy offer',offer);

                if (!offer?.offerId && !isNewProlong) {
                    this.loadProcessCurrent = false;
                    this.loadProlongationModal = true;
                    this.$refs.prolongationModal.show(order);

                    Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
                    return;
                }
                // ищем автоюриста в новом оффере
                const autolawyerData = this.getAutolawyerData(offer, order);

                //order.offer.autolawyerCompanyId = autolawyerData?.companyId;
                order.offer.autolawyerZone = autolawyerData?.zone;
                //order.offer.autolawyerId = autolawyerData?.id;
                order.offer.autolawyerOfferId = autolawyerData?.offerId;
                order.offer.autolawyerPrice = autolawyerData?.price;
                order.offer.autolawyerProgram = autolawyerData?.program;
                order.offer.autolawyerZone =  autolawyerData?.zone,
                order.offer.autolawyerAutoCategoryType =  autolawyerData?.auto_category_type, // ?? null,
                // to order.autolawyer
                order.autolawyerPrice = autolawyerData?.price;
                if (order.autolawyer) {
                    order.autolawyer.id = autolawyerData?.id;
                    order.autolawyer.price = autolawyerData?.price;
                    order.autolawyer.program = autolawyerData?.program;
                    //order.autolawyer.company_id = autolawyerData?.companyId;
                    order.autolawyer.zone = autolawyerData?.zone;
                    order.autolawyer.auto_category_type = autolawyerData?.auto_category_type;
                }

                order.offer.listAutolawyer = offer?.listAutolawyer || [];

                dataCalculate.cityInfo = order.insurant.cityObject;
                dataCalculate.cityName = order.insurant.cityObject?.name_full_name_ua || order.insurant.city;
                dataCalculate.car = {
                    vin: order.insuranceObject.vin,
                    year: order.insuranceObject.year,
                    brand: order.insuranceObject.brand,
                    model: order.insuranceObject.model,
                    number: order.insuranceObject.number,
                    withoutVin: order.insuranceObject.withoutVin
                };

                this.loadProcessCurrent = false;

                this.$store.commit('SET_SEARCH_PARAMS', dataCalculate);
                this.$store.commit('SET_OSAGO_OFFERS', null);

            }

            if (order.typeId === 3) {

                let periodId = order.offer.period?.id;

                let paramsGreenCard = {
                    carType: order.insuranceObject.autoCategory,
                    country: order.offer.country.id,
                    periodOption: periodId,
                    startDate: startDate,
                    userType: order.insurant.customerTypeId
                }

                let responseGreen = await calculateGreenCard(paramsGreenCard.country, paramsGreenCard.carType, paramsGreenCard.userType, paramsGreenCard.startDate, paramsGreenCard.periodOption);

                offer = this.$_.find(responseGreen?.data?.data, (item) => {
                    return item.company.id === order.companyInfo.companyId;
                });
                order.offer.dateFromMax = offer?.dateFromMax;
                //замена (name : EUROPE) на "Європа"
                const countryGreenCard = this.countriesOptions.find(item => item.id = order.offer.country.id);
                order.offer.country = countryGreenCard;

                if (!offer?.offerId && !isNewProlong) {

                    this.loadProcessCurrent = false;
                    this.loadProlongationModal = true;
                    this.$refs.prolongationModal.show(order);

                    Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
                    return;
                }

                this.loadProcessCurrent = false;

            }

          if (order.typeId === 5) {
            this.humans = [
              {name: 'Діти до 1 року ', value: 0, min: 0, max: 1, birthDates: [], id:1},
              {name: 'Діти до 3 років ', value: 0, min: 2, max: 3,  birthDates: [], id:2},
              {name: 'Підлітки до 18 років ', value: 0, min: 4, max: 18,  birthDates: [], id:3},
              {name: 'Дорослі до 69 років ', value: 0, min: 19, max: 69, birthDates: [], id:4},
              {name: 'Дорослі 70-75 років ', value: 0, min: 70, max: 75, birthDates: [], id:5},
              {name: 'Літні 76-100 років ', value: 0, min: 76, max: 100, birthDates: [], id:6}
            ];
            this.birthDates = [];
            this.fillHumans(order.insurants);

            const calculateDate =  this.calculateDate(startDate, order.offer.period_option, order.offer.yearProgram);

            const paramsTourism = {
              date: calculateDate.startDate,
              end: calculateDate.endDate,
              days: order.offer.period_option,
              country: {id:order.offer.country.id,ewa_id:order.offer.country.ewa_id,name:order.offer.country.name},
              tourists: order.offer.tourists,
              humans: this.humans,
              yearProgram: order.offer.yearProgram,
              birthDates: this.birthDates,
              filters: {
                "franchise": "",
                "options_list": [],
                "activities": {
                  "name": "Без активності",
                  "value": "no_activities"
                },
                "activitiesItem": "Без активностей"
              },
              activities: "no_activities",
            };

            let responseTourism = await calculateTourism(paramsTourism);

            offer = this.$_.find(responseTourism?.data?.data, (item) => {
              return item.company.id == order.companyInfo.companyId && item.tripProgram == order.offer.trip_program && item.tourists == order.offer.tourists && parseInt(item.coverage) == parseInt(order.offer.coverage) && item.franchise == order.offer.franchise && item.covid_help == order.offer.covid_help;
            });

            let userInfo = {
              "identificationCode":order.insurant.documentation.uin,
              "withoutIdentificationCode":order.insurant.documentation.withoutUin,
              "name":order.insurant.name,
              "surname":order.insurant.surname,
              "dateBirth":order.insurant.dateBirthAt,
              "phone":order.insurant.phone,
              "mail":order.insurant.email,
              "city":order.insurant.cityObject,
              "street":order.insurant.street,
              "house":order.insurant.house,
              "apartment":order.insurant.apartment,

              "documentation": {
                //TODO текущее значение нобходимо будет брать с словаря(api)
                documentType: order.insurant.documentation.documentType,
                type: order.insurant.documentation.type,
                serial: order.insurant.documentation.serial,
                number: order.insurant.documentation.number,
                issuedBy: order.insurant.documentation.issuedBy,
                dateOfIssue: order.insurant.documentation.dateOfIssue,
                endDateOfIssue: order.insurant.documentation.endDateOfIssue,
              },
              "documentType":order.insurant.documentation.documentType,
              "passportSerial":order.insurant.documentation.serial,
              "passportNumber":order.insurant.documentation.number,
              "passportDate":order.insurant.documentation.dateOfIssue
            };
            let TouristsList = [];

            order.insurants.forEach((insurant) => {
              TouristsList.push({ name: insurant.name, surname: insurant.surname, dateBirth: insurant.dateBirthAt, identificationCode: insurant.documentation.uin,
                withoutIdentificationCode:insurant.documentation.withoutUin,
                passportSerial: insurant.documentation.serial, passportNumber: insurant.documentation.number, passportDate: insurant.documentation.dateOfIssue, documentType: insurant.documentation.type, passportIssuedBy: insurant.documentation.issuedBy,
                passportEndDate: insurant.documentation.endDateOfIssue,});
            })

            if (!offer?.offerId && !isNewProlong) {
              this.$store.commit('CLEAR_TOURISM_FULL_ORDER');
              this.$store.commit('CLEAR_TOURISM_INFO_TOURISTSLIST');
              this.$store.commit('CLEAR_SEARCH_TOURISM_PARAMS');
              this.$store.commit('CLEAR_TOURISM_INFO_OFFER');
              this.$store.commit('CLEAR_TOURISM_INFO_USER');
              this.$store.commit('SET_SEARCH_TOURISM_PARAMS', paramsTourism);
              this.$store.commit('SET_TOURISM_INFO_USER', userInfo);
              this.$store.commit('SET_TOURISM_INFO_TOURISTSLIST', TouristsList);

              //this.$router.push({name: `tourismResult`});

              this.loadProcessCurrent = false;
              this.loadProlongationModal = true;
              this.$refs.prolongationModal.show(order);

              Vue.set(this.loadMoreOptions, order.id + order.typeId, false);

              return;
            }
          }
            if (offer?.offerId){
              order.price = offer.price;
              order.offer.id = offer.offerId;
              order.company = offer.company;
              order.offer.available_documents = offer.available_documents ?? null;
            }
            Vue.set(this.loadMoreOptions, order.id + order.typeId, false);

            this.declare(order, true, isProlong, isNewProlong);
        },*/
       /* declare: function(order, isCopy = false, isProlong = false, isNewProlong = false) {
            let carType = this.getCarType(order);
            let startDate = this.getStartDate(order);
            if(order.typeId === 5) {
            //console.log('Declare OFFER', order)

            let offerInfo = {
              "id":order.offer.external_id,
              "company":order.companyInfo.info,
              "tripProgram":order.offer.trip_program,
              "title":order.companyInfo.name,
              "options":order.offer.options,
              "programs":order.offer.programs,
              "members":order.offer.members,
              "ages":order.offer.ages,
              "external_id":order.offer.external_id,
              "startDate":order.offer.startDate,
              "endDate":order.offer.endDate,
              "moduleId":order.moduleId,
              "period_option":order.offer.period_option,
              "risks":order.offer.risks,
              "price":order.offer.price,
              "limit":order.offer.coverage,
              "limit_currency":order.offer.limit_currency,
              "offerId":order.offer.id,
              "purposeOfTrip":order.offer.purposeOfTrip,
              "fullPrice":order.allPrice,
              "yearProgram":order.offer.yearProgram,
              "allowed_documents": order.offer?.allowed_documents ?? null,
              "available_documents": order.offer?.available_documents ?? null,
            };
            offerInfo.company.id = order.companyInfo.companyId;

            this.humans = [
              {name: 'Діти до 1 року ', value: 0, min: 0, max: 1, birthDates: [], id:1},
              {name: 'Діти до 3 років ', value: 0, min: 2, max: 3,  birthDates: [], id:2},
              {name: 'Підлітки до 18 років ', value: 0, min: 4, max: 18,  birthDates: [], id:3},
              {name: 'Дорослі до 69 років ', value: 0, min: 19, max: 69, birthDates: [], id:4},
              {name: 'Дорослі 70-75 років ', value: 0, min: 70, max: 75, birthDates: [], id:5},
              {name: 'Літні 76-100 років ', value: 0, min: 76, max: 100, birthDates: [], id:6}
            ];

            this.birthDates = [];
            this.fillHumans(order.insurants);

            const calculateDate =  this.calculateDate(startDate, order.offer.period_option, order.offer.yearProgram);

            let searchInfo = {
              date: calculateDate.startDate,
              end: calculateDate.endDate,
              days: order.offer.period_option,
              country: {id:order.offer.country.id,ewa_id:order.offer.country.ewa_id,name:order.offer.country.name},
              tourists: order.offer.tourists,
              humans: this.humans,
              yearProgram: order.offer.yearProgram,
              birthDates: this.birthDates,
              filters: {
                "franchise": "",
                "options_list": [],
                "activities": {
                  "name": "Без активності",
                  "value": "no_activities"
                },
                "activitiesItem": "Без активностей"
              },
              activities: "no_activities",
            };





            let userInfo = {
              "identificationCode":order.insurant.documentation.uin,
              "withoutIdentificationCode":order.insurant.documentation.withoutUin,
              "name":order.insurant.name,
              "surname":order.insurant.surname,
              "dateBirth":order.insurant.dateBirthAt,
              "phone":order.insurant.phone,
              "mail":order.insurant.email,
              "city":order.insurant.cityObject,
              "street":order.insurant.street,
              "house":order.insurant.house,
              "apartment":order.insurant.apartment,

              "documentation": {
                //TODO текущее значение нобходимо будет брать с словаря(api)
                documentType: order.insurant.documentation.documentType,
                type: order.insurant.documentation.type,
                serial: order.insurant.documentation.serial,
                number: order.insurant.documentation.number,
                issuedBy: order.insurant.documentation.issuedBy,
                dateOfIssue: order.insurant.documentation.dateOfIssue,
                endDateOfIssue: order.insurant.documentation.endDateOfIssue,
              },
              "documentType":order.insurant.documentation.documentType,
              "passportSerial":order.insurant.documentation.serial,
              "passportNumber":order.insurant.documentation.number,
              "passportDate":order.insurant.documentation.dateOfIssue,
            };

            let TouristsListInfo = [];

            order.insurants.forEach((item, key) => {
              TouristsListInfo.push({
                "name":item.name,
                "surname":item.surname,
                "dateBirth":item.dateBirthAt,
                "identificationCode":item.documentation.uin,
                "withoutIdentificationCode":item.documentation.withoutUin,
                "passportSerial":item.documentation.serial,
                "passportNumber":item.documentation.number,
                "passportDate":item.documentation.dateOfIssue,
                "passportIssuedBy":item.documentation.issuedBy,
                "passportEndDate":item.documentation.endDateOfIssue,
                "documentType": item.documentation.type,
              });
            });

            let params = {
              type: "next",
              statusId: 0
            };

              if (isProlong || isNewProlong) {
                offerInfo.prolongOrderId = order.id;
              }

            this.$store.commit('CLEAR_TOURISM_INFO_TOURISTSLIST');
            this.$store.commit('CLEAR_SEARCH_TOURISM_PARAMS');
            this.$store.commit('CLEAR_TOURISM_INFO_OFFER');
            this.$store.commit('CLEAR_TOURISM_INFO_USER');
            this.$store.commit('CLEAR_TOURISM_FULL_ORDER');
            this.$store.commit('SET_TOURISM_INFO_OFFER', offerInfo);
            this.$store.commit('SET_TOURISM_INFO_USER', userInfo);
            this.$store.commit('SET_TOURISM_INFO_TOURISTSLIST', TouristsListInfo);
            this.$store.commit('SET_SEARCH_TOURISM_PARAMS', searchInfo);
            this.orderId  = (isCopy) ? null : order.id;
            this.$store.commit('SET_TOURISM_FULL_ORDER', this.orderId);


              if(isNewProlong){
                Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
                this.loadProcessNew = false;
                this.$router.push({name: `tourismResult`, query: {from: 'prolongation'}});
              }else{
                this.$router.push({name: 'tourismSteps', query: {from: 'prolongation'}});
              }
          }
            else if(order.typeId === 3) {

                let periodId = order.offer.period?.id;

                let userTypes= this.$store.getters.CUSTOMER_TYPES
                let finishedAt = null;
                let userTypeList = this.$store.getters.CUSTOMER_TYPES;
                let autoTyprsList = this.$store.getters.AUTO_TYPES_LIST;
                order.offer.customerType = order.insurant.customerTypeId;
                let searchInfo = {};
                if (isNewProlong) {
                   searchInfo = {
                    carType: carType,
                    country: order.offer.country,
                    periodOption: periodId ? this.periodOptions.find((item) => item.id === periodId) : this.getOrderContractPeriod(order),
                    startDate: startDate,
                    userType: userTypes.find(option => option.id === order.offer.userType),
                    dateFromMax: order.offer?.dateFromMax,
                  }
                }else{
                   searchInfo = {
                    carType: autoTyprsList.find((item) => item.external_id == order.insuranceObject.autoCategory),
                    userType: userTypeList.find(option => option.id === order.offer.userType),
                    country: {id: order.offer.country.id,
                      name: order.offer.country.name,
                      name_ru: order.offer.country.name},
                    startDate: this.getStartDate(order), //order.offer.startDate,
                    periodOption: order.offer.period,
                  }
                }

                let infoUser = {
                    documentType: order.insurant.documentation.documentType,
                    identificationCode: order.insurant.documentation.uin,
                    erdpyCode: order.insurant.documentation.uin,
                    name: order.insurant.name,
                    surname: order.insurant.surname,
                    dateBirth: order.insurant.dateBirthAt,
                    phone: order.insurant.phone,
                    mail: order.insurant.email,
                    city: order.insurant.city,
                    street: order.insurant.street,
                    house: order.insurant.house,
                    apartment: order.insurant.apartment,
                    internationalPassport: (order.insurant.documentation.documentType == 2) ? order.insurant.documentation.number : null,
                    passportSerial: order.insurant.documentation.serial,
                    passportNumber: ((order.insurant.documentation.documentType == 4)) ? order.insurant.documentation.number : null,
                    passportCompany: order.insurant.documentation.issuedBy,
                    passportDate: order.insurant.documentation.dateOfIssue,
                    withoutIdentificationCode: order.insurant.documentation.withoutUin,
                    withoutDocuments:order.insurant.documentation.withoutDocuments,
                    documentation: {
                      type: order.insurant.documentation.documentType,
                      serial: order.insurant.documentation.serial,
                      number: order.insurant.documentation.number,
                      issuedBy: order.insurant.documentation.issuedBy,
                      dateOfIssue: order.insurant.documentation.dateOfIssue,
                      endDateOfIssue: order.insurant.documentation.dateOfIssue,
                    },
                };

                let carInfo = {
                    vin: order.insuranceObject.vin,
                    year: order.insuranceObject.year,
                    brand: order.insuranceObject.brand,
                    model: order.insuranceObject.model,
                    number: order.insuranceObject.number,
                    city: {
                        id:order.insuranceObject.registrationPlaceId,
                        name:order.insuranceObject.registrationPlace,
                        name_full_name_ua:order.insuranceObject.registrationPlace
                    },
                    withoutVin: order.insuranceObject.withoutVin
                };

                if (isProlong || isNewProlong) {
                    order.offer.prolongOrderId = order.id;

                    if (order.contract?.finishedAt) {
                        finishedAt = moment(order.contract?.finishedAt, "DD.MM.YYYY").add(1, 'days').format("DD.MM.YYYY")
                    }

                    order.offer.startDate = finishedAt;
                }

                order.offer.company = order.companyInfo.info;
                order.offer.autoCategoryType = order.insuranceObject.autoCategory;
                order.offer.company.id = order.offer.company.company_id;
                order.offer.company.logo = process.env.VUE_APP_ADMIN_DOMAIN+order.offer.company.logo;
                order.offer.companyNamePublic = order.companyInfo.publicName;
                order.offer.offerId = order.offer.id;

                this.$store.commit('CLEAR_GREENCARD_FULL_ORDER');
                this.$store.commit('SET_GREENCARD_INFO_OFFER', order.offer);
                this.$store.commit('SET_GREENCARD_SEARCH_PARAMS', searchInfo);
                this.$store.commit('SET_GREENCARD_INFO_USER', infoUser);
                this.$store.commit('SET_GREENCARD_INFO_CAR', carInfo);
                this.$store.commit('SET_GREENCARD_FULL_ORDER', (isCopy) ? null : order.id);
                this.$store.commit('SET_GREENCARD_CONTRACT_ID', order.contractId);
                if(isNewProlong){
                  Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
                  this.loadProcessNew = false;
                  this.$router.push({name: `greenCardResults`, query: {from: 'prolongation'}});
                }else{
                  this.$router.push({name: 'greenCardSteps', query: {from: 'prolongation'}});
                }

            }
            else if(order.typeId === 1) {
            let finishedAt = null;
            this.isCopyProcess = false;

            Vue.set(this.loadMoreOptions, order.id + order.typeId, true);

            let startDate = this.getStartDate(order);

            let paramsCar = {
                vin: order.insuranceObject.vin,
                year: order.insuranceObject.year,
                brand: order.insuranceObject.brand,
                model: order.insuranceObject.model,
                number: order.insuranceObject.number,
                withoutVin: order.insuranceObject.withoutVin
              }

            let userData = {
              name: order.insurant.name,
              phone: order.insurant.phone,
              email: order.insurant.email,
              surname: order.insurant.surname,
              dateBirth: order.insurant.dateBirthAt,
              patronymic: order.insurant.patronymic,
              identificationCode: order.insurant.documentation.uin,
              withoutIdentificationCode: order.insurant.documentation.withoutUin,
              withoutDocuments: order.insurant.documentation.withoutDocuments,
              documentType: order.insurant.documentation.type,
              documentation: {
                type: order.insurant.documentation.documentType,
                serial: order.insurant.documentation.serial,
                number: order.insurant.documentation.number,
                issuedBy: order.insurant.documentation.issuedBy,
                dateOfIssue: order.insurant.documentation.dateOfIssue,
                endDateOfIssue: order.insurant.documentation.dateOfIssue,
              },
              address: {
                street: order.insurant.street,
                house: order.insurant.house,
                number: order.insurant.apartment,
                cityId: order.insurant.apartment,
                cityObject: order.insurant.cityObject,
              }
            }

            let params = {
              fromProlongation: isNewProlong ? true : false,
              isTaxi: order.insuranceObject.isTaxi ? 1 : 0,
              isEuroCar: order.insuranceObject.isEuroCar ? 1 : 0,
              experience: this.experience,
              franchise: 'all',
              zone: order.insuranceObject?.registrationPlaceZone,
              period_id: order.offer?.period_id,
              autoCategoryType: order.insuranceObject.autoCategory,
              registrationPlaceId: order.insuranceObject.registrationPlaceId,
              cityName: order.insuranceObject.registrationPlace,
              otkDate: order.offer.otkDate ? this.getOrderEndDate(order, startDate) : null, //order.offer.otkDate ? moment(order.offer.otkDate).unix() : null,
              withoutOtk: order.withoutOtk ? 1 : 0,
              startDate: startDate,
              carTypeInfo: carType,
              cityInfo: order.insurant?.cityObject,
              customerPrivilege: order.insurant?.privilegeTypeId ?? null,
              registrationType: order.registrationType ?? null,
              customerType: order.insurant?.customerTypeId,
              endDate: order.offer.endDate ? this.getOrderEndDate(order, startDate) : null,
              car: paramsCar,
              dateFromMax: offer?.dateFromMax,
            };

            let requestData = {
                registrationType: order.registrationType,
                price: this.showPrice(order, true),
                dgoPrice: this.showDgoPrice(order),
                autolawyerPrice: this.showAutolawyerPrice(order),
                franchise: order.offer?.franchise,
                autoCategoryType: order.insuranceObject.autoCategory,
                registrationPlaceId: order.offer.cityId,
                customerPrivilege: order.insurant.privilegeTypeId,
                customerType: order.insurant.customerTypeId,
                period_id: order?.offer?.period_id,
                startDate: order?.offer?.startDate,
                cityName: order.insuranceObject.registrationPlace || "",
                zone: order.insuranceObject?.registrationPlaceZone,
                otkDate: order.offer.otkDate ? this.getOrderEndDate(order, startDate) : null,//order.offer.otkDate ? moment(order.offer.otkDate).unix() : null,
                endDate: order.offer.endDate ? this.getOrderEndDate(order, startDate) : null,
                //order.offer.endDate ? this.getOrderEndDate(order, startDate) : null
                isTaxi: order.insuranceObject.isTaxi,
                isEuroCar: order.insuranceObject.isEuroCar,
                cityInfo: {
                    //TODO это бардак, нужно зарефакторить текущие данные по догоову. ЧТо бы были одинаковые ключи
                    name_full_name_ua: order.insurant.city,
                    name: order.insurant.city,
                },
                car: paramsCar
            }

            //console.log('declare order',order);

            let offer = {
                programmName: order.offer?.programmName,
                dateFromMax: order.offer?.dateFromMax,
                franchise: order.offer?.franchise,
                moduleId: order.moduleId,
                companyName: order.companyInfo.publicName,
                listDgo: order.listDgo,
                dgoId: order?.dgo.id,
                dgoCoverage: order?.dgo.compensation,
                dgoCompanyId: order?.dgo.company_id,
                dgoPrice: this.showDgoPrice(order),
                // autolawyer
                listAutolawyer: order?.offer.listAutolawyer,
                //autolawyerId: order?.offer?.autolawyerId,
                autolawyerCompanyId: order?.offer?.autolawyerCompanyId,
                autolawyerPrice: this.showAutolawyerPrice(order),
                autolawyerProgram: order?.offer.autolawyerProgram,
                price: this.showPrice(order, true),
                discountPrice: this.showDiscount(order),
                offerId: order.offer.id,
                cityRegistration: order.offer.city,
                companyId: order.companyInfo.companyId,
                company: {
                    inn_status: order?.company?.innStatus
                },
                allowed_documents: order.offer?.allowed_documents ?? null,
                available_documents: order.offer?.available_documents ?? null,
                userData: userData,
            };
                //console.log('declare offer',offer);
            if (isProlong) {
                offer.prolongOrderId = order.id;

                if (order.contract?.finishedAt) {
                    finishedAt = moment(order.contract?.finishedAt, "DD.MM.YYYY").add(1, 'days').format("DD.MM.YYYY")
                }

                offer.startDate = finishedAt;
            }

            let fullInfoOrder = {
                autoCategoryType : order.insuranceObject.autoCategory,
                cityRegistration: order.insurant.cityObject,
                customer: userData,
                orderId: (isCopy) ? null : order.id,
                parentOrderId: (isCopy) ? order.id : null,
                car: paramsCar
            };

            if(offer.dgoPrice && offer.dgoCoverage){
                let dgoSelected = {
                    id: offer.dgoPrice,
                    cost: offer.dgoPrice,
                    coverage: offer.dgoCoverage
                }
                offer.listDgo.unshift(dgoSelected);
            }

            const dateInfoEndDateUnix = this.getOrderEndDate(order, startDate);
            let orderDateInfo = {
                startDate: startDate,
                endDate: (order.offer.endDate || order.offer.otkDate) ? moment.unix(dateInfoEndDateUnix).format('DD.MM.YYYY') : moment.unix(dateInfoEndDateUnix).subtract(1,'day').format('DD.MM.YYYY'),
                period: this.getOrderContractPeriod(order).name
            }

            Vue.set(this.loadMoreOptions, order.id + order.typeId, false);

                this.$store.commit('SET_SEARCH_PARAMS', params);
                this.$store.commit('SET_OSAGO_OFFERS', null);
                this.$store.commit('SET_INFO_ORDER', {offer: offer, requestData: requestData});
                this.$store.commit('SET_FULL_INFO_ORDER', fullInfoOrder);
                this.$store.commit('SET_DATE_INFO', orderDateInfo);
                this.$store.commit('SET_CAR_INFO', paramsCar);
                if(isNewProlong){
                  this.loadProcessNew = false;
                  Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
                  this.$router.push({name: `osagoResults`, query: params});
                }else{
                  this.$router.push({name: 'orderSteps', query: {from: 'prolongation'}});
                }
            }
            else{
                let finishedAt = null;
                let infoUser = {
                    city: {
                        id: order.insurant.cityObject.id,
                        name: order.insurant.cityObject.name_ua,
                        name_full_name_ua: order.insurant.cityObject.name_full_name_ua,
                    },
                    apartment: order.insurant.apartment,
                    house: order.insurant.house,
                    street: order.insurant.street,
                    phone: order.insurant.phone,
                    name: order.insurant.name,
                    surname: order.insurant.surname,
                    mail: order.insurant.email,
                    dateBirth: order.insurant.dateBirthAt,
                    inn: order.insurant.documentation.uin,
                    documentType: order.insurant.documentation.documentType,
                    passportNumber: order.insurant.documentation.passportNumber,
                    passportCompany: order.insurant.documentation.issuedBy,
                    passportDate: order.insurant.documentation.dateOfIssue,
                    internationalPassport: order.insurant.documentation.internationalPassport,
                }

                let searchInfo = {
                    from: {
                        id: order.offer.fromCity.id,
                        name: order.offer.fromCity.name_ua,
                        name_full_name_ua: order.offer.fromCity.name_full_name_ua,
                    },
                    to: {
                        id: order.offer.toCity.id,
                        name: order.offer.toCity.name_ua,
                        name_full_name_ua: order.offer.toCity.name_full_name_ua,
                    },
                    endDate: order.contract.finishedAt,
                    startDate: order.contract.startedAt,
                    ticketNumber: order.offer.ticketNumber,
                    ticketPrice: order.offer.ticketPrice,
                }

                this.$store.commit('SET_BUS_SEARCH_PARAMS', searchInfo);
                this.$store.commit('SET_BUS_INFO_USER', infoUser);
                this.$store.commit('CLEAR_BUS_FULL_ORDER');
                this.$router.push({name: 'busSteps'});
            }
        },
        getAutolawyerData(newOffer, oldOrder) {
            if ((oldOrder.autolawyerPrice && oldOrder.autolawyer) && newOffer.listAutolawyer.length) {
                const foundAutolawyer = newOffer.listAutolawyer.find(item => item.program === oldOrder.autolawyer.program);
                return foundAutolawyer ? foundAutolawyer : null;
            }
            return null;
        },*/
        isDraftTab: function () {
            return this.listQuery.statusId === this.draftStatus;
        },
        isCanceledTab: function () {
            return this.listQuery.statusId === this.canceledStatus;
        },
        isDeclaredTab: function () {
            return this.listQuery.statusId === this.declaredStatus;
        },
        isProlongation: function () {
            return this.listQuery.statusId === this.prolongationStatus.id;
        },
        showMonths: function(order) {
            if(order.typeId !== this.orderTypes['dgo'] && order.usageMonths != null && order.usageMonths != undefined && order.usageMonths != '')
                return true;
            return false;
        },
        /*showAllPrice: function(order) {
            let usageMonthsOn = 0;
            if(order.usageMonths != null && order.usageMonths != undefined && order.usageMonths != '')
            {
                if(Array.isArray(order.usageMonths))
                {
                    if(order.usageMonths.length > 0)
                    {
                        order.usageMonths.forEach((item, key) => {

                            if(item != 0)
                            {
                                usageMonthsOn ++;
                            }
                        });
                    }
                }
            }
            let finishPrice = 0;
            if (usageMonthsOn > 0) {
                if(usageMonthsOn > 6)
                    usageMonthsOn = 6;
                let proc = order.price/100;
                let sumMin = proc * (usageMonthsOn * 5);
                finishPrice = order.price - sumMin;
                finishPrice = finishPrice.toFixed(2);
            }
            else
                finishPrice = order.price;
            if(order.discountPrice > 0)
            {
                var realDiscountPercent = 100 - order.discountPrice * 100 / order.price;
                finishPrice = Number(finishPrice - (finishPrice / 100 * realDiscountPercent)).toFixed(2);
                //finishPrice = Number( finishPrice-(finishPrice-order.discountPrice)).toFixed(2);
            }
            //finishPrice = Number(finishPrice) + parseFloat((order?.dgo?.price || 0));
            return finishPrice;
            //return order.allPrice;
        },
        showTypeInsurance: function(order) {
          const orderType = this.allOrderTypes.find(type => type.id === order.typeId);
          return orderType ? orderType.name : 'Неизвестный тип страхования';
        },*/
        showMtsbuCode: function(order) {
            return order?.contract?.mtsbuCode || ( order.mtsbuCode || '');
        },
        activateTab :function (status) {
            this.$set(this.listQuery, 'statusId', status.id);
        },
    },
    watch: {
        'listQuery.statusId': function (newValue, oldValue) {
            return this.$emit('init-orders-list');
        }
    }
}
</script>
